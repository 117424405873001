import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ApiSettings, CompanyReportsSettings} from 'app/settings.class';
import {HttpClient, HttpParams} from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EntityConfirmModalComponent } from '../../entities/components/views/entity-confirm-modal.component';
import { prettyCase } from '../../shared/utilities.classes';
import { Router} from '@angular/router';

export interface RecentList {
  name: string;
  id: number
}

@Injectable({
  providedIn: 'root'
})
export class CompanyReportsService {
  private reportSubject: Subject<any> = new Subject;
  private reports: any[];
  public locking = false;
  public hasTaxomnomy: Subject<boolean> = new Subject();
  public selectedReport: Subject<any> = new Subject();
  public recentSearchUpdated: Subject<any> = new Subject();
  public onReportLocked: Subject<boolean> = new Subject();
  private mlStatus: any;
  private selectedReportFile = null;
  private reportEventFilterState = {
    reportDate: '',
    companyName: '',
    accountStandard: '',
    periodEndDate: '',
    comment: '',
    dataEntryStatus: '',
    taxonomyId: '',
    claimedBy: '',
    claimedByMe: false,
    sortTerms: [
      {
        columnName: 'marketcap',
        sortOrder: 'desc'
      },
      {
        columnName: 'report_date',
        sortOrder: 'desc'
      }
    ]
  }
  constructor(private http: HttpClient, private toaster: ToastrService, private modalService: NgbModal,  private router: Router) { }

  setReports(reports: any[]) {
    this.reports = reports;
    this.reportSubject.next(reports);
  }

  getReports(refresh?: boolean, companyId?: number) {
    if ((refresh || !this.reportSubject) && companyId) {
      return this.http.get(CompanyReportsSettings.BASE_URL + '/' + CompanyReportsSettings.COMPANY_ENDPOINT + '/' + companyId);
    }
    return this.reportSubject;
  }
  getCompanyData(id: number): Observable<any> {
    return this.http.get(ApiSettings.BASE_URL + '/' + CompanyReportsSettings.COMPANY_ENDPOINT + '/' + id);
  }

  getInstitutionData(id: number): Observable<any> {
    return this.http.get(ApiSettings.BASE_URL + '/institution/' + id);
  }

  saveEditordata(editorData: any, isId: number, reportName: string) {
    return this.http.post(CompanyReportsSettings.BASE_URL + '/' + reportName + '/' + isId + '/store_editor', editorData);
  }

  setReportLock(lockData: any, reportId) {
    return this.http.put(CompanyReportsSettings.BASE_URL + '/' + reportId, lockData);
  }

  setRecent(lsName, list: RecentList) {
    try {
      let currentData = JSON.parse(localStorage.getItem(lsName));
      if (currentData && currentData.length) {
        if (currentData.filter(data => data.id === list.id).length === 0) {
          currentData.unshift(list);
        }
      } else {
        currentData = [list];
      }
      currentData.splice(5, currentData.length);
      localStorage.setItem(lsName, JSON.stringify(currentData));
      this.recentSearchUpdated.next(lsName);
    } catch (e) {
      console.log('setRecent : error', e);
      return;
    }
  }

  removeFromRecent(lsName, list: RecentList, remove?: boolean) {
    try {
      const currentData = JSON.parse(localStorage.getItem(lsName));
      if (currentData && currentData.length) {
        const index = currentData.findIndex(data => data.id === list.id);
        if (index > -1) {
          currentData.splice(index, 1);
        }
      }
      localStorage.setItem(lsName, JSON.stringify(currentData));
      this.recentSearchUpdated.next(lsName);
    } catch (e) {
      console.log('removeFromRecent : error', e);
      return;
    }
  }

  getRecent(lsName: string) {
    try {
      const currentData = JSON.parse(localStorage.getItem(lsName));
      if (currentData && currentData.length) {
        return currentData;
      } else {
        return [];
      }
    } catch (e) {
      console.log('getRecent : error', e);
      return [];
    }
  }

  // get missing reports
  getMissingReports(filterParams: any): Observable<any> {
    return this.http.get(CompanyReportsSettings.MISSING_REPORTS_ENDPOINT , {
      params: filterParams
    })
  }


  // get reports which not locked
  getNonLockedReports(pageNumber: number): Observable<any> {
    return this.http.get(CompanyReportsSettings.BASE_URL + `?q:locked=f:false&page=${pageNumber}&order_by=updated_at`)
  }
  // get check page reports
  getCheckReports(filterParams: any): Observable<any> {
    return this.http.get(CompanyReportsSettings.BASE_URL + '/check', {
      params: filterParams
    })
  }
  // activate send to ml function
  sendToML(reportId): Observable<any > {
    return this.http.post(`${CompanyReportsSettings.BASE_URL}/send_report_instance_to_ml/${reportId}`,{
      report_file_id:this.selectedReportFile.id
    });
  }
  // set ml status
  setMLCurrentStatus(status) {
    this.mlStatus = status;
  }
  // get ml status
  mlCurrentStatus(): any {
    return this.mlStatus?.ml_request_status;
  }

  // set selected report file for ml
  setSelectedReportFile(data) {
    this.selectedReportFile = data;
  }
  // get selected report file for ml
  getSelectedReportFile() {
    return this.selectedReportFile;
  }

  // get company report file
  getCompanyReportFile(reportFileId: number){
    return this.http.get(`${ApiSettings.BASE_URL}/company_report_file/${reportFileId}`);
  }

  // get company report files statuses based on report id
  getReportFileStatuses(reportId: number) {
    return this.http.get(`${CompanyReportsSettings.BASE_URL}/validate_report_for_ml/${reportId}`);
  }
  // past statement
  getIncomeStatementById(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/income_statement/${id}/`)
  }
  getBalanceSheetById(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/balance_sheet/${id}/`)
  }
  // past balance sheet
  getCashFlowById(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/cash_flow/${id}/`)
  }
  // past period kpi
  getPeriodKpiById(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/period_kpi/${id}/`)
  }
  // past snapshot kpi
  getSnapshotKpiById(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/snapshot_kpi/${id}/`)
  }

  formatPreviousYearData(data: any, previousYearValues, previousValueSource) {
    if(data.past_statements !== null && data.past_statements.length ) {
      if(data.past_modules) {
        this.formatPreviousYearValue(data.past_modules,previousYearValues, previousValueSource);
      }
      this.setPreviousYearValue(data.modules,previousYearValues, previousValueSource);
      data.showPrevious = true;
    } else if (data.past_modules && data.past_modules.length > 1) {
      this.formatPreviousYearValue(data.past_modules,previousYearValues, previousValueSource);
      this.setPreviousYearValue(data.modules,previousYearValues, previousValueSource);
      data.showPrevious = true;
    } else {
      data.showPrevious = false;
    }
  }
  formatPreviousYearValue(past_moduleList, previousYearValues, previousValueSource) {
    for (let index = 0; index < past_moduleList.length; index++) {
      previousYearValues[past_moduleList[index].module_id] = past_moduleList[index].value_rep;
      previousValueSource[past_moduleList[index].module_id] =  past_moduleList[index].value_source;
      if (past_moduleList[index].children && past_moduleList[index].children.length > 0) {
        this.formatPreviousYearValue(past_moduleList[index].children, previousYearValues, previousValueSource)
      }
    }
  }
  setPreviousYearValue(moduleList, previousYearValues, previousValueSource) {
    for (let index = 0; index < moduleList.length; index++) {
      moduleList[index].prev_year_value = previousYearValues[moduleList[index].module_id];
      moduleList[index].prev_value_source = previousValueSource[moduleList[index].module_id];
      if (moduleList[index].children && moduleList[index].children.length > 0) {
        this.setPreviousYearValue(moduleList[index].children, previousYearValues, previousValueSource)
      }
    }
  }
  getCompanyReportFilesData(reportFileId: number, pageNumber: number){
    return this.http.get(`${CompanyReportsSettings.BASE_URL}/report_files_for_ml/${reportFileId}`, {
      params: {
        page:pageNumber,
        order_by:'updated_at'
      }});
  }
  getStripedWebsiteString(companyWebsite: string): string {
    if (companyWebsite) {
      return companyWebsite.replace(/(^\w+:|^)\/\//, '');
    }
    return '';
  }

  // get all statements
  getAllStatement(id: number) {
    return this.http.get(CompanyReportsSettings.BASE_URL + `/all-statements/${id}/`)
  }
  private cancelMLRequest(id: number) {
    return this.http.put(CompanyReportsSettings.BASE_URL + `/cancel_ml_request/`,{
      company_report_id: id
    })
  }
  cancelRequest(id: any) {
    if(id) {
      const modalRef = this.modalService.open(EntityConfirmModalComponent,
        {
          size: 'md'
        });

      const data = {
        title: "Cancel Request",
        message: 'Are you sure you want to cancel ML request?',
        alert: false
      }

        modalRef.componentInstance.modalData = data;
        modalRef.result.then((result) => {
          this.cancelMLRequest(id).subscribe((data: any) => {
            if(data.request_status) {
              this.setMLCurrentStatus({ml_request_status : data.request_status});
              this.toaster.success('ML Request Cancelled Successfully');
            }
          }, (error) => {
            console.error(error);
            this.toaster.error('Something went wrong');
          })
        });
      } else {
        this.toaster.error('Something went wrong');
    }
  }
  getCompanyReportMismatch(companyId: number, pageNumber: number){
    return this.http.get(`${ApiSettings.BASE_URL}/company/${companyId}/reports-with-semi-annual-conflict`, {
      params: {
        page:pageNumber,
        order_by:'updated_at'
      }});
  }
// missing and upcoming events start
  getMissingCompanyReportEvents(pageNumber: number){
    return this.http.get(`${CompanyReportsSettings.BASE_URL}/missing-report-events/`, {
      params: {
        page: String(pageNumber)
      }
    })
  }
  getUpcomingCompanyReportEvents(pageNumber: number){
    return this.http.get(`${CompanyReportsSettings.BASE_URL}/upcoming-report-events/`, {
      params: {
        page: String(pageNumber)
      }
    })
  }
  patchUpcomingReportEventStatus(id, data) {
    return this.http.patch(`${ApiSettings.BASE_URL}/company_report_event/${id}/`, data);
  }
  getReportEventCustomFilterData(name, query, pageNumber) : Observable<any>{
    if(name === 'CustomMissingCompanyReportEvent') {
      return this.http.get(ApiSettings.BASE_URL + '/company_report/missing-report-events/' + query, {
        params: {
          page: String(pageNumber)
        }
      });
    }
    if(name === 'CustomUpcomingCompanyReportEvent'){
      return this.http.get(ApiSettings.BASE_URL + '/company_report/upcoming-report-events/' + query, {
        params: {
          page: String(pageNumber)
        }
      });
    }
  }
  getTaxonomyNameIncomeStatement(type: string, page: number = 1, per_page?: number): Observable<any> {
    let params = new HttpParams();
    params = params.set('page', page.toString());
    if (per_page) {
      params = params.set('per_page', per_page.toString())
    }
    return this.http.get(ApiSettings.BASE_URL + '/taxonomy?q:taxonomy_type=' + type, {
      params
    });
  }
  deleteReportEvent(id: number) {
    return this.http.delete(ApiSettings.BASE_URL + '/company_report_event/' + id + '/');
  }
  // missing and upcoming events end
  getCompanyReportingStandard(companyId: number){
    return this.http.get(`${ApiSettings.BASE_URL}/company/${companyId}/latest-reporting-standard`);
  }

  getComparisonPrefix(data: any): string {

    if(data) {
      return data.charAt(0).toUpperCase() + " - ";
    }

    return '';
  }

  setReportEventsFilterState(filterObject) {
    this.reportEventFilterState = filterObject;
  }

  get getReportEventsFilterState() {
    return this.reportEventFilterState;
  }

  patchClaimStatus(id: number, isClaim) {
    if(isClaim) {
      return this.http.patch(ApiSettings.BASE_URL + '/company_report_event/' + id + '/claim', null);
    } else {
      return this.http.patch(ApiSettings.BASE_URL + '/company_report_event/' + id + '/unclaim', null);
    }
  }
  goToEntity(event, entity, id) {
    event.stopPropagation();
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['entity', entity, id])
    );

    window.open(url, '_blank');
  }

  getCalendarEventTypes() {
    return this.http.get(ApiSettings.BASE_URL + `/calendar_event_type/`)
  }
  clearValues(tree: any[]): void {
    tree.forEach(node => {
      node.value_rep = '';
      node.display_value = '';
      if (node.children) {
        this.clearValues(node.children);
      }
    });
  }
}

