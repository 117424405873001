import { Component, OnInit, Input } from '@angular/core';
import { TreeNode } from './balance-sheet-editor.component';
import { CompanyReportsService } from 'app/company-reports/services/company-reports.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'con-balance-sheet-tree-layout',
  templateUrl: './tree-layout.component.html'
})
export class BalanceSheetTreeLayoutComponent implements OnInit {
  @Input() balanceSheet: { company_report: any, modules: TreeNode[], quantity: any,past_statement_id: any, pastBalanceSheet: any, past_statements: any[];currency: any, id: any, showPrevious: boolean, balance_sheet_date: any, past_date: any };
  @Input() locked: boolean;
  @Input() entity: any;
  @Input() reportLock: boolean;

  public processingTree = false;
  public activateActionButtons = false;
  public validationErrors = [];
  public noDataMessage = 'No data to display';
  dateRangePicker = new UntypedFormControl();
  public previousYearValues: any = {};
  public previousValueSource: any = {};
  loadingData = false;
  autoCalc = true;
  constructor(public companyService: CompanyReportsService,
    private router: Router,
    private toastr: ToastrService,
  ) { }
  ngOnInit() {
    /**AutoCalc function will be enabled by default and the toggle functionality will be hidden for now.
     * Following condition will be uncommented once the toggle functionality is enabled back
    */
    // if(this.balanceSheet.company_report.report_type === 'interim') {
    //   this.autoCalc = false;
    // }
    if(this.balanceSheet?.past_statement_id) {
      this.dateRangePicker.setValue(this.balanceSheet?.past_statement_id)
    } else if(this.balanceSheet?.past_statements !== null && this.balanceSheet.past_statements.length) {
      this.dateRangePicker.setValue(this.balanceSheet.past_statements[0].id);
      this._check();
    } else {
      this.dateRangePicker.setValue(null);
    }
  }

  revertTree(modules, quantity) {
    modules.forEach(element => {
      element.display_value = element.initialValue_rep ? (element.initialValue_rep / quantity).toString() : null;
      element.value_rep = element.initialValue_rep;
      element.has_error = false;
      element.has_limit_error = false;
      if (element.children && element.children.length > 0) {
        this.revertTree(element.children, quantity);
      }
    });
  }
  saveTree(editedModules, isId: number) {

    this.calculateBalance().then(() => {
      if (this.allowSave()) {
        const data = [];
        this.processingTree = true;
        getEditedModules(editedModules);
        function getEditedModules(modules) {
          modules.forEach(element => {
            if (element.initialValue_rep === undefined) {
              element.initialValue_rep = null;
            }
            if (element.initialValue_rep !== element.value_rep) {
              if (element.figure_id === null) {
                data.push({ module_id: element.module_id, value_rep: element.value_rep });
              } else {
                data.push({ figure_id: element.figure_id, value_rep: element.value_rep });
              }
            }
            if (element.children && element.children.length > 0) {
              getEditedModules(element.children);
            }
          });
        }
        this.companyService.saveEditordata({ editor_data: data }, isId, 'balance_sheet').subscribe((response: any) => {
          if (response && response.modules && response.quantity) {
            this.activateActionButtons = false;
            this.balanceSheet.modules = response.modules;
            this._check();
            this.toastr.success('Data inserted successfully!', 'Balance Sheet');
          } else {
            this.toastr.error('Sorry, some error occurred', 'Balance Sheet');
          }

          this.processingTree = false;
        },
          err => {
            if(err.hasOwnProperty('type')){
              if(err.type === "LOCKED_ERROR" && !this.reportLock){
                this.toastr.warning('ML data is being processed', 'ML Error');
              } else {
                this.toastr.warning(err.data.message);
              }
            }
            if (err.status === 404) {
              this.router.navigate(['balance-sheet', 'error'], { skipLocationChange: true });
            }
            this.processingTree = false;
          });
      }

    }).catch((balance) => {
      this.toastr.error('Balance sheet is not tally, balance is ' + balance, 'Balance sheet');
    })

  }
  allowSave() {
    return (this.validationErrors.length === 0);
  }
  enableActionButtons(event) {
    this.activateActionButtons = true;
  }
  calculateBalance() {
    return new Promise((resolve, reject) => {

      const levelAccounts = this.balanceSheet.modules.filter((node) => {
        return node.level;
      });
      let sumOfAsset = 0;
      let sumOfLiabilityAndEquity = 0;
      let totalLiabilityEquity = 0;

      levelAccounts.forEach((node: any) => {
        const display_value = parseFloat(node.display_value);
        if (display_value) {
          if (node.type === 'Asset') {
            sumOfAsset += display_value;
          } else if (node.type === 'Liability' || node.type === 'Equity') {
            sumOfLiabilityAndEquity += display_value;
          } else if (node.type === 'Liability-Equity') {
            totalLiabilityEquity += display_value;
          }
        }

      });
      if (totalLiabilityEquity !== sumOfAsset && this.autoCalc) {
        reject((sumOfAsset - totalLiabilityEquity))
      } else {
        resolve('');
      }
    })

  }
  onReachingMaxValue(event) {
    const node = event.node;
    const index = this.validationErrors.findIndex((element) => {
      return (element.name === node.name && element.module_id === node.module_id && element.fragment_id === node.fragment_id);
    });

    if (event.action === 'add') {
      if (index === -1) {
        this.validationErrors.push(node);
      }
      const maxValue = 999999999999999.9999999 / node.quantity;
      this.toastr.error('Enter a value less than ' + maxValue + ' ' + this.balanceSheet.quantity.name, 'Limit exceeded');
    } else if (event.action === 'remove' && index !== -1) {
      this.validationErrors.splice(index, 1);
    }
  }
  getYear(date) {
    if (date) {
      return moment(date).year();
    } else {
      return '';
    }
  }
  _check() {
    if (this.balanceSheet.id && this.dateRangePicker.value) {
      this.loadingData = true;
      this.companyService.getBalanceSheetById(+this.balanceSheet.id).subscribe((initialBalanceSheet: any) => {
        this.companyService.getBalanceSheetById(+this.dateRangePicker.value).subscribe((pastBalanceSheet: any) => {
          initialBalanceSheet.past_modules = pastBalanceSheet.modules;
          this.previousYearValues = {};
          this.previousValueSource = {};
          this.companyService.formatPreviousYearData(initialBalanceSheet, this.previousYearValues, this.previousValueSource);
          this.balanceSheet.modules = initialBalanceSheet.modules;
          this.balanceSheet.pastBalanceSheet = pastBalanceSheet.pastBalanceSheet;
          this.loadingData = false;
        }, (error) => {
          console.error(error);
          this.loadingData = false;
        });
      }, (error) => {
        console.error(error);
        this.loadingData = false;
      });
    }
  }
  clearFormData() {
    if(this.balanceSheet.modules) {
      this.companyService.clearValues(this.balanceSheet.modules);
      this.activateActionButtons = true;
    }
  }
  toggleCalcOff() {
    this.autoCalc = !this.autoCalc;
  }
}
