import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'app/auth/services/profile.service';
import { CalendarSettings } from 'app/settings.class';
import { DateConverterService } from 'app/shared/services/date-converter.service';
import * as moment from 'moment';

@Component({
  selector: 'con-calendar-overview',
  templateUrl: './calendar-overview.component.html'
})
export class CalendarOverviewComponent {

  public tierId: number = CalendarSettings.TIER_ID;
  public institutionTierId: number = CalendarSettings.INSTITUITION_TIER_ID;
  public now: string = this.dateConverter.toEntityString(moment());
  public tomorrow: string = this.dateConverter.toEntityString(moment().add(1, 'days').startOf('day'));
  public monthExplorerExpanded = false;
  public addCalendarEventExpanded = false;

    constructor(private router: Router,
                public profile: ProfileService,
                private dateConverter: DateConverterService) {}

    public navigateToCompany(company: any) {
        this.router.navigate(['calendar', 'company', company.id]);
    }

    public navigateToInstitution(institution: any) {
        this.router.navigate(['calendar', 'institution', institution.id]);
    }

    public getNow() {
        return
    }

  calendarEventCreated(data: any) {
    this.monthExplorerExpanded = false;
  }

}
