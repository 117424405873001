<con-loader *ngIf="isLoading() || loading"></con-loader>
<div *ngIf="!loading && !hidden">
    <span *ngIf="!_canShow && !_canEdit && entityAlreadyExists()">
        You are not allowed to see/edit this entity
    </span>
    <span *ngIf="!_canStore && !entityAlreadyExists()">
        You are not allowed to create new entities of this type
    </span>
    <con-pretty-entity-shower
        [fromDetailPage]="fromDetailPage"
        *ngIf="!_canEdit && _canShow && entityAlreadyExists()"
        [entity]="obj"
        [entityName]="entityDescription.name"
        [newTab]="newTab"
        [redirectButton]="redirectButton"
    >
    </con-pretty-entity-shower>
    <form *ngIf="(
            (_canEdit && entityAlreadyExists())
            || (_canStore && !entityAlreadyExists())
        ) && (
            _canShow
            || !entityAlreadyExists()
        )" [formGroup]="entityForm" (ngSubmit)="onSubmit()"  novalidate>
        <ng-container *ngFor="let field of fields">
            <div class="form-group"
                    *ngIf="!fixedValues.hasOwnProperty(field.key) && entityForm.get(field.key)" 
                    [ngClass]="{ 'has-danger': (fieldErrors[field.key] && fieldErrors[field.key].length)
                                || (!entityForm.get(field.key).untouched && entityForm.get(field.key).status === 'INVALID') }">
                <label><small>{{ field.label }}</small></label>
                <div>
                    <con-flexible-input [entityForm]="entityForm" [control]="entityForm.get(field.key)" [field]="field" [resetKey]="resetKey" [reverseDates]="combinedStatements" [isSaving]="isSaving"></con-flexible-input>
                    <con-form-errors [control]="entityForm.get(field.key)" [extra]="fieldErrors[field.key]"></con-form-errors>
                </div>
            </div>
        </ng-container>
        <ng-container *ngFor="let relation of getBelongsToRelations()">
            <div class="form-group"
                *ngIf="!fixedValues[relation.foreign_key]"
                [ngClass]="{ 'has-danger': (fieldErrors[relation.foreign_key] && fieldErrors[relation.foreign_key].length)
                                            || (!entityForm.get(relation.foreign_key)?.untouched && entityForm.get(relation.foreign_key)?.status === 'INVALID') }">
                <label><small>{{ relation.label }}</small></label>
                <div>
                    <con-belongs-to-form
                            [parentCompany]="parentCompany"
                            [ownerType]="ownerType"
                            [entity]="obj"
                            [form]="entityForm"
                            [relation]="relation"
                            [fromShareholder]="fromShareholder"
                            [fromEntityForm]="true"
                            (onSelect)="resetErrorsForRelation(relation, $event)">
                    </con-belongs-to-form>
                    <small class="mt-1" *ngIf="entityName === 'CalendarEventComment' && relation.foreign_key == 'language_id'">
                        <ng-container *ngIf="direktProfileLanguage?.language[direktProfileLanguage?.eventId]?.languageName; else elseLanguageInfo">Only {{ direktProfileLanguage?.language[direktProfileLanguage?.eventId]?.languageName }} comments will be sent to Direkt.</ng-container>
                        <ng-template #elseLanguageInfo>Only comments in the language associated with the Direkt profile will be sent to Direkt.</ng-template>
                        {{autoTranslateMessage}}
                    </small>
                    <con-form-errors [control]="entityForm.get(relation.key)"
                                 [extra]="fieldErrors[relation.foreign_key]">
                    </con-form-errors>
                </div>
            </div>
        </ng-container>
        <ul class="list-group list-group-flush mb-2" *ngIf="entityAlreadyExists() && showWhenChanged">
            <li class="list-group-item">
                <strong>Updated at: </strong><con-date-shower [date]="obj['updated_at']"></con-date-shower> <con-avatar [userId]="obj['updated_by']" [size]="32"></con-avatar>
            </li>
            <li class="list-group-item">
                <strong>Created at: </strong><con-date-shower [date]="obj['created_at']"></con-date-shower> <con-avatar [userId]="obj['created_by']" [size]="32"></con-avatar>
            </li>
        </ul>
        <div class="alert alert-danger" *ngFor="let message of generalErrors">
            {{ message }}
        </div>
        <con-loader *ngIf="isSaving"></con-loader>
        <div class="mt-10">
          <button *ngIf="fromPressRelease && !entityAlreadyExists()" type="button"
                [disabled]="entityForm.pristine || isSaving" class="btn btn-success" (click)="onSubmit(false, true)">Save & Add Categories</button>
          <button *ngIf="showEditButton" type="button"
                [disabled]="(entityForm.pristine && !initialSaveEnabled) || isSaving && !initialSaveEnabled" class="btn btn-success" (click)="onSubmit(false, true)">Save & Comment</button>
          <button type="submit"
                [disabled]="(entityForm.pristine && !initialSaveEnabled) || isSaving" class="btn btn-success">Save</button>
          <button type="reset" (click)="revert()"
                [disabled]="entityForm.pristine" class="btn btn-warning">Revert</button>
          <button type="button" *ngIf="_canDelete && entityAlreadyExists()" (click)="delete()"
                  class="btn btn-danger">Delete</button>
          <a *ngIf="redirectButton.show" [routerLink]="redirectButton.link" class="btn btn-dark" target="_blank">{{redirectButton.label | titlecase}}</a>
        </div>
    </form>
</div>
