import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ApiSettings } from '../../../../settings.class';
import { take, takeUntil } from 'rxjs/operators';
import { DragulaService } from 'ng2-dragula';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopupComponent } from 'app/shared/components/confirmation-popup.component';
import { EstimatesService } from 'app/estimates/services/estimates.service';

interface TaxonomySaveError {
  type: string;
  message: string;
  fields: {
    name?: string[];
    modules?: string[];
    survey_id?: string[];
  };
  general?: string[];
}

@Component({
  selector: 'con-custom-taxonomy',
  templateUrl: './custom-taxonomy.component.html',
  styleUrls: ['./custom-taxonomy.component.scss']
})
export class CustomTaxonomyComponent implements OnInit, OnDestroy {

  @ViewChildren('selectDropdown') selectDropdowns: QueryList<any>;
  @Input() surveyEntity: any;

  nameOptions: {name: string, value: any}[] = [];
  selectedValue= 'std';  // Default value
  formArray: FormArray = new FormArray([]);
  taxonomyName = new FormControl('');
  data = [];
  surveyId: number;
  oldIndex: number;
  oldChildIndex: number;
  oldGrandChildIndex: number;
  destroyed$ = new Subject();
  errorResponse: any;
  position = 1;
  showEditMode = false;
  showCreateMode = false;
  loading = true;
  taxonomyListingData = undefined;
  completeModuleList: {name: string, value: any}[] = [];
  selectedNames: string[] = [];
  delete_line_ids: number[] = [];
  showPreLoader = false;
  surveryDate: string
  isLoadingTaxonomy = false;
  constructor(private http: HttpClient, private toaster: ToastrService, private route: ActivatedRoute, private dragulaService: DragulaService, private modalService: NgbModal, private estimatesService: EstimatesService) {
  }

  ngOnInit() {
    this.route.params.subscribe((routeData) => {
      this.surveryDate = this.surveyEntity.date;
      if(routeData.hasOwnProperty('id')) {
        this.surveyId = +routeData.id;
      }
      if(this.surveyEntity.taxonomy_id) {
        this.taxonomyListingData = this.surveyEntity.taxonomy;
      } else {
        this.taxonomyListingData = null;
      }
    });

    this.http.get(ApiSettings.BASE_URL+`/estimates/all_modules`).subscribe((data: any) => {
      this.completeModuleList = this.convertArrayToNameOptionsType(data);
    });
    // Dragula configuration
    if(this.dragulaService.find('parents') === undefined) {
      this.dragulaService.createGroup('parents', {
        removeOnSpill: false,
        moves: function (el, container, handle) {
          return handle.classList.contains('parentHandle');
        }
      });
    }
    if(this.dragulaService.find('children') === undefined) {
      this.dragulaService.createGroup('children', {
        removeOnSpill: false,
        moves: function (el, container, handle) {
          return handle.classList.contains('childHandle');
        }
      });
    }
    if(this.dragulaService.find('grandChildren') === undefined) {
      this.dragulaService.createGroup('grandChildren', {
        removeOnSpill: false
      });
    }
    // Subscribe to the drop event
    this.dragulaService.drop('parents').pipe(takeUntil(this.destroyed$)).subscribe(({ el, target, source, sibling }) => {
      this.onDrop(el, target, source, sibling);
    });
    this.dragulaService.drag('parents').pipe(takeUntil(this.destroyed$)).subscribe(({ name, el, source }) => {
      this.oldIndex = this.domIndexOf(el, source);
    });
    this.dragulaService.drop('children').pipe(takeUntil(this.destroyed$)).subscribe(({ el, target, source, sibling }) => {
      const targetParentId = +target.parentElement?.id;
      const sourceParentId = +source.parentElement?.id;
      if (!isNaN(targetParentId) && !isNaN(sourceParentId)) {
        this.onDropingChild(sourceParentId, targetParentId, this.oldChildIndex, this.domIndexOf(el, target));
      }
    });
    this.dragulaService.drag('children').pipe(takeUntil(this.destroyed$)).subscribe(({ name, el, source }) => {
      this.oldChildIndex = this.domIndexOf(el, source);
    });
    this.dragulaService.drop('grandChildren').pipe(takeUntil(this.destroyed$)).subscribe(({ el, target, source, sibling }) => {
      const targetGrandParentId = +target.parentElement?.parentElement?.parentElement?.id;
      const sourceGrandParentId = +source.parentElement?.parentElement?.parentElement?.id;
      const targetParentId = +target.parentElement?.parentElement?.id;
      const sourceParentId = +source.parentElement?.parentElement?.id;
      if (!isNaN(targetGrandParentId) && !isNaN(sourceGrandParentId) && !isNaN(targetParentId) && !isNaN(sourceParentId)) {
        this.onDropingGrandChild(sourceGrandParentId, +source.parentElement.id, this.oldGrandChildIndex, targetGrandParentId, +target.parentElement.id, this.domIndexOf(el, target));
      }
    });
    this.dragulaService.drag('grandChildren').pipe(takeUntil(this.destroyed$)).subscribe(({ name, el, source }) => {
      this.oldGrandChildIndex = this.domIndexOf(el, source);
    });

  }

  onDropingChild(fromParent: number, toParent: number, fromChild: number, toChild: number) {
    let droppedParent = this.formArray.controls[fromParent].get('children') as FormArray;
    let newParent = this.formArray.controls[toParent].get('children') as FormArray;
    const expandChildrenControl = (this.formArray.controls[toParent] as FormGroup).controls['expandChildren']
    expandChildrenControl.setValue(true);
    const movedControl = droppedParent.at(fromChild);
    droppedParent.removeAt(fromChild);
    if (fromParent === toParent) {
      droppedParent.insert(toChild, movedControl);
    } else {
      newParent.insert(toChild, movedControl);
    }
  }

  onDropingGrandChild(fromParent: number, fromChild: number, fromGrandChild: number, toParent: number, toChild: number, toGrandChild: number) {
    const droppedParent = this.formArray.controls[fromParent].get('children') as FormArray;
    const droppedChild = (droppedParent.controls[fromChild].get('children') as FormArray);
    const newParent = this.formArray.controls[toParent].get('children') as FormArray;
    const newChild = (newParent.controls[toChild].get('children') as FormArray);
    const movedControl = droppedChild.at(fromGrandChild);
    droppedChild.removeAt(fromGrandChild);
    if (fromParent === toParent && fromChild === toChild) {
      droppedChild.insert(toGrandChild, movedControl);
    } else {
      newChild.insert(toGrandChild, movedControl);
    }
  }

  showCreateForm(){
    this.showCreateMode = true;
    this.makeApiCall(this.selectedValue);
  }
  //Handler for getting options.
  getOptions(data) {
    let opts = [];
    let recursiveFn = (data) => {
      data.map(item => {
        opts.push({name: item.name, value: item});
        if (Array.isArray(item.children)) {
          recursiveFn(item.children);
        }
      });
    }
    recursiveFn(data);
    return opts;
  }
  onDropDownChange(newValue: string) {
    this.selectedValue = newValue;
    this.loading = true;
    this.makeApiCall(newValue);
  }
  makeApiCall(value: string) {
    this.http.get(ApiSettings.BASE_URL + `/estimates/modules/${value}`).pipe(take(1)).subscribe((res: any) => {
      this.data = res;
      //Initialize the nameOptions array after fetching the data
      this.filterExistingModuleItems();
      this.nameOptions = this.getOptions(this.data);
      this.nameOptions = this.mergeUnique(this.nameOptions, this.completeModuleList);
      this.formArray = this.createFormArray(this.data);

      this.refreshDropDownList();
      this.loading = false;
    }, (err) => {
      console.log("API Error", err);
      this.loading = false;
    })
  }

  createFormArray(data: any[]): FormArray {
    let formArray: FormArray = new FormArray([]);

    data.forEach(item => {
      let formGroup: FormGroup = new FormGroup({
        item: new FormControl(item), // Add a default "Select One" option
        alias: new FormControl(item.alias),
        allowTags: new FormControl(this.showCreateMode),
        children: new FormArray([]),  // Add an empty FormArray for children
        expandChildren: new FormControl(item.children && item.children.length > 0)  // Add property to toggle children display
      });

      if (item.children && item.children.length > 0) {
        item.children.forEach(child => {
          let childFormGroup: FormGroup = new FormGroup({
            item: new FormControl(child), // Add a default "Select One" option
            alias: new FormControl(child.alias),
            allowTags: new FormControl(this.showCreateMode),
            children: new FormArray([])  // Add another empty FormArray for grandchildren
          });

          if (child.children && child.children.length > 0) {
            child.children.forEach(grandchild => {
              let grandchildFormGroup: FormGroup = new FormGroup({
                item: new FormControl(grandchild), // Add a default "Select One" option
                alias: new FormControl(grandchild.alias),
                allowTags: new FormControl(this.showCreateMode),
              });
              (childFormGroup.get('children') as FormArray).push(grandchildFormGroup);
            });
          }

          (formGroup.get('children') as FormArray).push(childFormGroup);
        });
      }

      formArray.push(formGroup);
    });
    if(this.surveyEntity?.locked) {
      formArray.disable();
      this.taxonomyName.disable();
    }
    return formArray;
  }
  getLevel(formGroup: FormGroup) {
    let level = 0;
    let currentGroup = formGroup;

    // Increase the level while parent exists
    while (currentGroup.parent) {
      level++;
      currentGroup = currentGroup.parent as FormGroup;
    }

    return level;
  }
  addChild(formGroup: FormGroup) {
    let childrenArray = formGroup.get('children') as FormArray;

    if (!childrenArray) {
      childrenArray = new FormArray([]);
      formGroup.addControl('children', childrenArray);
    }
    const defautAddChildItem = {
      alias: "",
      module_id: null,
      name: "",
      type: formGroup.value.item.type,
      position: "",
      unique_id: null,
      line_id: null
    }
    const newChildGroup = new FormGroup({
      item: new FormControl(defautAddChildItem),
      alias: new FormControl(''),
      children: new FormArray([]),
      expandChildren: new FormControl(true),  // Set initial value to true for expanding by default
      allowTags: new FormControl(true)      // Add allowTags property to enable tags for new rows
    });
    formGroup.get('expandChildren').setValue(true);

    childrenArray.push(newChildGroup);
  }
  removeChild(formGroup: FormGroup, childIndex: number) {
    const childrenArray = formGroup.get('children') as FormArray;
    let flattenedItems = this.extractItemsFormValue(childrenArray.controls.at(childIndex).value);
    this.appendRemovedItemsToNameOptions(flattenedItems);
    setTimeout(() => {
      this.refreshDropDownList();
    })
    childrenArray.removeAt(childIndex);
  }
  removeParent(parentIndex: number) {
    let flattenedItems = this.extractItemsFormValue(this.formArray.controls.at(parentIndex).value);
    this.appendRemovedItemsToNameOptions(flattenedItems);
    setTimeout(() => {
      this.refreshDropDownList();
    })
    this.formArray.removeAt(parentIndex);
  }
  removeGrandchild(formGroup: FormGroup, grandchildIndex: number) {
    const grandchildrenArray = formGroup.get('children') as FormArray;
    let flattenedItems = this.extractItemsFormValue(grandchildrenArray.controls.at(grandchildIndex).value);
    this.appendRemovedItemsToNameOptions(flattenedItems);
    setTimeout(() => {
      this.refreshDropDownList();
    })
    grandchildrenArray.removeAt(grandchildIndex);
  }
  appendRemovedItemsToNameOptions(flattenedItems) {
    flattenedItems.forEach(item => {
      let newItem;

      // If item contains only a 'name' key
      if (Object.keys(item).length === 1 && item.hasOwnProperty('name')) {
        // pass - we may need this logic later to check newly added item
      } else {
        // Otherwise, convert the 'item' into the desired format.
        newItem = {
          name: item.name,
          value: item
        };
        // Check if an item with the same name already exists in this.nameOptions
        let itemExists = this.nameOptions.some(option => option.name === newItem.name);
        // If an item with the same name does not exist, then add the new item
        if (!itemExists) {
          this.nameOptions.push(newItem);

        }
      }

    })
    this.nameOptions = [... this.nameOptions];
  }
  extractItemsFormValue(dataObj) {
    let items = [];

    // Checking if an object has item and pushing it to items array
    if (dataObj.hasOwnProperty('item')) {
      items.push(dataObj.item);
    }

    // If 'children' exists and is an array.
    if (Array.isArray(dataObj.children)){
      dataObj.children.forEach(child => {
        // Recursively find items in children and concatenate the results.
        items = items.concat(this.extractItemsFormValue(child));
      });
    }

    return items;
  }

  addGrandchild(formGroup: FormGroup) {
    let grandchildrenArray = formGroup.get('children') as FormArray;

    if (!grandchildrenArray) {
      grandchildrenArray = new FormArray([]);
      formGroup.addControl('children', grandchildrenArray);
    }
    const defautAddGrandChildItem = {
      alias: "",
      module_id: null,
      name: "",
      type: formGroup.value.item.type,
      position: "",
      line_id: null,
      unique_id: null
    }
    const newGrandchildGroup = new FormGroup({
      item: new FormControl(defautAddGrandChildItem),
      alias: new FormControl(''),
      allowTags: new FormControl(true),  // Add allowTags property to enable tags for new rows
    });

    grandchildrenArray.push(newGrandchildGroup);
  }

  addParentModule() {
    const defaultParentItem = {
      alias: "",
      module_id: null,
      name: "",
      type: "IncomeStatementModule",
      position: "",
      unique_id: null,
      line_id: null
    }
    const newChildGroup = new FormGroup({
      item: new FormControl(defaultParentItem),
      alias: new FormControl(''),
      children: new FormArray([]),
      expandChildren: new FormControl(true),  // Set initial value to true for expanding by default
      allowTags: new FormControl(true)      // Add allowTags property to enable tags for new rows
    });

    this.formArray.push(newChildGroup);
    setTimeout(() => {
      this.selectDropdowns.last.focus();
    }, 10)
  }
  mapFormValueToModules(formValue: any[]): any[] {
    const modules: any[] = [];
    if(formValue) {
      formValue.forEach((formItem) => {
        const module: any = {
          alias: formItem.alias,
          type: formItem.item.type? formItem.item.type : "IncomeStatementModule",
          position: formItem.item.position,
          name: formItem.item.name,
          module_id: formItem.item.module_id ? formItem.item.module_id : null,
          children: this.mapFormValueToModules(formItem.children)
        };

        if (this.showEditMode) {
          module.line_id = formItem.item.line_id
        }

        modules.push(module);
      });
    }

    return modules;
  }
  // position key reset using a Depth-First-Search (DFS)
  resetPositions(arr: any[]) {
    arr.forEach((node) => {
      // Assign the updated position to the current node
      node.position = String(this.position);
      this.position++;

      // Recursively update position for all children of the current node
      if (node.children && node.children.length > 0) {
        this.resetPositions(node.children);
      }
    });
  }
  // Reset FormArray positions
  resetFormArrayPositions(formArray: FormArray) {
    formArray.controls.forEach((formGroup: FormGroup, index) => {
      // Reset the position of the current formGroup
      const item = formGroup.get('item').value;
      item.position = String(this.position);
      this.position++;
      // Recursively reset position for all children of the current formGroup
      let childrenArray = formGroup.get('children') as FormArray;
      if (childrenArray && childrenArray.length > 0) {
        this.resetFormArrayPositions(childrenArray);
      }
    });
  }
  saveCustomTaxonomy() {

    this.position = 1;
    let modules = this.mapFormValueToModules(this.formArray.value);
    this.resetPositions(modules);
    this.position = 1;
    this.resetFormArrayPositions(this.formArray);
    let postData  = {
      name: this.taxonomyName.value,
      survey_id:this.surveyId,
      modules: modules
    }
    if(this.showCreateMode) {
      this.http.post(ApiSettings.BASE_URL + `/estimates/taxonomy/`, postData).pipe(take(1)).subscribe((res: any) => {
        this.toaster.success("Taxonomy created successfully", "Custom Taxonomy");
        this.showEditMode =false;
        this.showCreateMode = false;
        this.errorResponse = null;
        this.taxonomyName.setErrors(null);
        this.taxonomyListingData = res;
        this.estimatesService.getCurrentSelectedSurvey().pipe(take(1)).subscribe((survey) => {
          this.estimatesService.setCurrentSelectedSurvey({...survey, taxonomy_id: res.id, taxonomy: res}, true);
        })
      }, (err) => {
        this.toaster.error("Taxonomy creation failed", "Custom Taxonomy")
        if(err.data) {
          this.errorResponse = err.data;
          if( this.errorResponse.fields &&  this.errorResponse.fields.hasOwnProperty('name')){
            // If it exists, set the error to the "taxonomyName" form control
            this.taxonomyName.setErrors({serverError:  this.errorResponse.fields.name[0]});
          }
          this.displayFormErrors(this.formArray.controls, err.data);
        }
      })
    }
    if(this.showEditMode) {
      delete postData.survey_id;
      this.http.patch(ApiSettings.BASE_URL + `/estimates/taxonomy/${this.taxonomyListingData.id}`, postData).pipe(take(1)).subscribe((res: any) => {
        this.errorResponse = null;
        this.taxonomyName.setErrors(null);
        this.showEditForm();
        this.toaster.success("Taxonomy updated successfully", "Custom Taxonomy");
      }, (err) => {
        this.toaster.error("Taxonomy update failed", "Custom Taxonomy")
        if(err.data) {
          this.errorResponse = err.data;
          if( this.errorResponse.fields &&  this.errorResponse.fields.hasOwnProperty('name')){
            // If it exists, set the error to the "taxonomyName" form control
            this.taxonomyName.setErrors({serverError:  this.errorResponse.fields.name[0]});
          }
          this.displayFormErrors(this.formArray.controls, err.data);
        }
      })
    }
  }


  toggleChildrenDisplay(formGroup: FormGroup) {
    const expandChildren = formGroup.get('expandChildren') as FormControl;
    expandChildren.setValue(!expandChildren.value);
  }
  onDrop(el, target, source, sibling) {
    // write logic moveItemInArray this.formArray
    // get current index
    const index = this.domIndexOf(el, target);
    // get old index using this.formArray.value.findIndex(...)
    const item = this.formArray.at(this.oldIndex);
    this.formArray.removeAt(this.oldIndex);
    // insert element at new position
    this.formArray.insert(index, item);
  }

  domIndexOf(child, parent) {
    return Array.prototype.indexOf.call(parent.children, child);
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  transformToReadableFormat(responseObj: any): string {
    let readableErrors: string[] = [];
    for (let prop in responseObj) {
      if (Array.isArray(responseObj[prop])) {
        readableErrors = [...readableErrors, ...responseObj[prop]];
      }
    }
    return readableErrors.join(', ');
  }
  displayFormErrors(formGroups, errors: TaxonomySaveError) {
    formGroups.forEach((formGroup: FormGroup) => {
      const position = formGroup.get('item').value.position;
      const fieldErrors = errors.fields[position];
      if (fieldErrors) {
        let readableError = this.transformToReadableFormat(fieldErrors);
        formGroup.setErrors([readableError])
      }

      // Recursively check for nested formArray.
      let childrenArray = formGroup.get('children') as FormArray;
      if (childrenArray && childrenArray.length > 0) {
        this.displayFormErrors(childrenArray.controls, errors);
      }
    });
  }
  addTag = (name) => {
    return { name: name, value: {name: name} };
  };
  onAddItem(formControl: FormControl, name: string) {
    // Assuming this is new item
    const newItem = { name: name, value: name };

    // Add the new item to nameOptions array
    this.nameOptions.push(newItem);

    // Set the new item into the form control
    formControl.setValue(newItem);
  }
  compareFn(a, b) {
    return a && b ? a.name === b.name : a ===b;
  }

  showEditForm() {
    this.isLoadingTaxonomy = true;
    this.http.get(ApiSettings.BASE_URL + `/estimates/taxonomy/${this.taxonomyListingData.id}`).pipe(take(1)).subscribe((res: any) => {
      this.data = res.modules;
      this.taxonomyName.setValue(res.name);
      //Initialize the nameOptions array after fetching the data
      this.nameOptions = this.getOptions(this.data);
      this.nameOptions = this.mergeUnique(this.nameOptions, this.completeModuleList);
      this.formArray = this.createFormArray(this.data);
      // console.log( this.formArray.value);
      this.refreshDropDownList();
      this.showEditMode = true;
      this.loading = false;
      this.isLoadingTaxonomy = false;
    }, (err) => {
      console.log("API Error", err);
      this.loading = false;
      this.isLoadingTaxonomy = false;
    })
  }
  mergeUnique(nameOptions, completeModuleList) {
    // Spread both arrays into a new array
    const combined = [...nameOptions, ...completeModuleList];

    return combined.reduce((acc, item) => {
      // Check if item with the same name and module_id exists in the accumulator
      const exist = acc.some(
        existItem =>
          existItem.name === item.name &&
          existItem.value.module_id === item.value.module_id
      );

      // If it doesn't exist, push the item to the accumulator
      if (!exist) {
        acc.push(item);
      }
      return acc;
    }, []);
  }
  filterExistingModuleItems() {
    this.completeModuleList = this.completeModuleList.filter((module) =>
      !this.getOptions(this.data).some((option) => option.name == module.name)
    );
  }
  convertArrayToNameOptionsType(arr: any[]): any[] {
    return arr.map(item => ({
      name: item.name,
      value: item
    }));
  }
  updateSurveyTaxonomy(data: any) {
    this.showPreLoader = true;
    const postData = {
      id: this.surveyId,
      taxonomy_id: data.id
    }
    this.http.patch(ApiSettings.BASE_URL + `/survey/${this.surveyId}/`, postData).pipe(take(1)).subscribe((res: any) => {
      this.toaster.success("Taxonomy updated successfully", "Custom Taxonomy");
      this.showEditMode =false;
      this.showCreateMode = false;
      this.errorResponse = null;
      this.taxonomyName.setErrors(null);
      this.taxonomyListingData = res?.taxonomy;
      this.showPreLoader = false;
      this.estimatesService.setCurrentSelectedSurvey(res, true);
    }, (err) => {
      this.showPreLoader = false;
      this.toaster.error("Taxonomy update failed", "Custom Taxonomy");
    })

  }

  showUpdateConfirmModal() {
    if(this.showCreateMode) {
      this.saveCustomTaxonomy();
    } else {
      const modalRef = this.modalService.open(ConfirmationPopupComponent, { size: 'md' })
      const modalData = {
        title: 'Are you sure you want to continue?',
        text: `Modifications done here will affect the existing and future surveys of all companies that use this taxonomy.`,
        subText: 'Contact administrator for clarifications.'
       };
      modalRef.componentInstance.modalData = modalData;
      modalRef.result.then(() => {
        this.saveCustomTaxonomy();
      }, (reason) => { });
    }
  }

  updateAlias(fc, data) {
    this.refreshDropDownList();
    fc.get('alias').setValue(data.value.alias);
  }
  getNamesFromTree(data) {
    let names = [];

    data.forEach(element => {
      names.push(element.name);

      if (element.children) {
        names = names.concat(this.getNamesFromTree(element.children));
      }
    });

    return names;
  }

  refreshDropDownList() {
    let modules = this.mapFormValueToModules(this.formArray.value);
    this.selectedNames = this.getNamesFromTree(modules);
  }

}
