import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'convertToTimezone'
})
export class ConvertToTimezonePipe implements PipeTransform {

  transform(utcValue: string|null, format: string|null = 'yyyy-MM-DD HH:mm:ss', timezone: string = 'Europe/Rome'): string {
    if (!utcValue) {
      return '';
    }
    const date = moment.utc(utcValue);
    return date.tz(timezone).format(format);
  }

}
