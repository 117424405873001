<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <div class="page-title mb-2 pb-3 pt-3">
        <h3 class="mb-0">Report Dates</h3>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading">
    <con-loader></con-loader>
  </ng-container>
  <ng-container *ngIf="!isLoading && !reportDatesPageError">
    <div class="d-flex justify-content-start align-items-center sticky-header non-sticky py-2">
      <h3 class="m-0"><span class="badge badge-dark">
        {{getDisplayTitle()}}
      </span></h3>
      <span *ngIf="entity?.company?.website" class="website-link-positioning">
        <a [href]="entity?.company?.website" class="link-fontsize" target="_blank">{{companyService.getStripedWebsiteString(entity?.company?.website)}}</a>
      </span>
      <div class="chip chip-inverse ml-auto">
        <con-avatar [userId]="entity.company.manager?.id" [size]="32"></con-avatar>
        {{ entity.company.manager?.first_name }} {{ entity.company.manager?.last_name }}
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card mb-2">
          <div class="card-header cursor-pointer" (click)="expandCreateForm()">
            <div class="d-flex justify-content-start align-items-center">
              <con-icon-by-name iconName="paperclip" [mutations]="['fw']" class="mr-1"></con-icon-by-name>
              New Calendar Event
              <div class="card-header-toggle ml-auto">
                <con-icon-by-name iconName="angle-right" [mutations]="getMutations(!showCreateForm)"></con-icon-by-name>
              </div>
            </div>
          </div>
          <div *ngIf="!hasPermission && showCreateForm" class="alert alert-danger m-3">
            {{permissionErrorMessage}}
          </div>
          <div class="card-body" *ngIf="showCreateForm && hasPermission">
            <form [formGroup]="calendarEventForm" (ngSubmit)="submitCalendarEvent()">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label><small>Date</small></label>
                    <con-day-input [control]="calendarEventForm.controls['from_date']" [field]="{key: 'from_date'}" class="mt-2"></con-day-input>
                    <div class="mt-1">
                      <con-form-errors [control]="calendarEventForm.controls['from_date']"></con-form-errors>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <ng-container *ngFor="let error of formErrors">
                    <div class="alert alert-danger">
                      {{ error }}
                    </div>
                  </ng-container>
                  <con-loader *ngIf="isSaving"></con-loader>
                  <button type="submit" class="btn btn-success" [disabled]="isSaving">Save</button>
                  <button type="button" class="btn btn-warning" (click)="initForm()" [disabled]="!calendarEventForm.dirty">Revert</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row white-bg mb-2" *ngIf="true">
      <div class="col-12">
        <div class="card">
          <con-entity-list 
            [columnsToDisplay]="['from_date', 'calendar_event_type.name', 'updated_at']" 
            [customColumnNames]="{ from_date: 'Date' }"
            [runUpdate]="refreshTable$" entityName="CalendarEvent" [showSearchFields]="false" 
            [fixedSearchParams]="eventDateRange"
            [sortFields]="{field: 'from_date', asc: false}">
          </con-entity-list>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!isLoading && reportDatesPageError">
    <div class="alert alert-danger">
      {{ reportDatesPageError }}
  </div>
  </ng-container>
</div>