export const environment = {
  production: false,
  stage: false,
  oldstage: false,
  dev: true,
  company_static_tier: 1,
  company_calendar_tier: 2,
  company_marketcap_tier: 3,
  company_shareholders_tier: 34,
  company_report_tier: 67,
  institution_calendar_tier: 1,
  api_host: 'https://lb.test.be.infrontdata.com',
  domain: 'lb.test.be.infrontdata.com',
  company_report_default_exchange: 'Nordic',
  press_release_default_exchange: 'Europe Main',
  press_release_milan_exchange_id: 510,
  press_release_euro_next_growth_milan_id: 1798203,
  calendar_event_types: ['Earnings'],
  calendar_event_default_source_type: 'User',
  press_release_input_timezone: 'Europe/Rome',
  date_approx_object : { id: 1, name: "Exact" },
  time_approx_object : { id: 1, name: "Exact" },
  calendar_event_comment_default_lang: { id: 4, name: "Swedish", native_name: "svenska", iso639_1: "sv", iso639_2: "swe"},
  report_dates_default_profile_id: 4,
  report_dates_time_approximation_id: 2,
};
