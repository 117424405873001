import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthModule } from './../auth/auth.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { EntitiesLayoutComponent } from './layout/entities-layout.component';
import { EntityStartComponent } from './layout/entity-start.component';
import { EntityDetailsComponent } from './layout/entity-details.component';
import { EntityListComponent } from './components/views/entity-list.component';
import { EntitySearchFieldComponent } from './components/views/entity-search-field.component';
import { EntitySearchInRelationComponent } from './components/views/entity-search-in-relation.component';
import { EntityHistoryListComponent } from './components/views/entity-history-list.component';
import { EntityContentByFieldComponent } from './components/views/entity-content-by-field.component';
import { EntitySearchByFieldComponent } from './components/views/entity-search-by-field.component';
import { PrettyEntityShowerComponent } from './components/views/pretty-entity-shower.component';
import { EntityPrimaryAlertComponent } from './components/views/entity-primary-alert.component';
import { EntityOutdatedModalComponent } from './components/views/entity-outdated-modal.component';
import { EntityConfirmModalComponent } from './components/views/entity-confirm-modal.component';
import { BelongsToFormComponent } from './components/forms/belongs-to-form.component';
import { HasManyFormComponent } from './components/forms/has-many-form.component';
import { EntityFormComponent } from './components/forms/entity-form.component';
import { EntityFormContentComponent } from './components/forms/entity-form-content.component';
import { EntityFormContentTableComponent } from './components/forms/entity-form-content-table.component';
import { MultiPositionEditorComponent } from './components/forms/multi-position-editor/multi-position-editor.component';
import { PivotFormComponent } from './components/forms/pivot-form.component';
import { ToManyEditorComponent } from './components/editors/to-many-editor.component';
import { BelongsToEditorComponent } from './components/editors/belongs-to-editor.component';
import { HasOneEditorComponent } from './components/editors/has-one-editor.component';
import { EntityHasDependenciesModalComponent } from './components/views/entity-has-dependencies-modal.component';
import { EntitiesService } from './services/entities.service';
import { ValidatorConversionService } from './services/validator-conversion.service';
import { RelationService } from './services/relation.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { IMaskModule } from 'angular-imask';
import { ReportFileEditorComponent } from './components/editors/report-file-editor.component';
import { CustomEntityListingComponent } from './components/views/custom-entity-listing.component';
import { CustomReportingEntityListingComponent } from './components/views/custom-reporting-entity-listing.component';
import { CustomEntityFormContentComponent } from './components/forms/custom-entity-form-content.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MomentModule,
    SharedModule,
    RouterModule,
    AuthModule,
    IMaskModule
  ],
  declarations: [
    EntitiesLayoutComponent,
    EntityStartComponent,
    EntityListComponent,
    EntityDetailsComponent,
    EntityFormComponent,
    EntityFormContentComponent,
    EntityFormContentTableComponent,
    MultiPositionEditorComponent,
    PivotFormComponent,
    EntitySearchFieldComponent,
    EntitySearchInRelationComponent,
    EntityHistoryListComponent,
    EntityContentByFieldComponent,
    EntitySearchByFieldComponent,
    PrettyEntityShowerComponent,
    BelongsToFormComponent,
    HasManyFormComponent,
    ToManyEditorComponent,
    BelongsToEditorComponent,
    HasOneEditorComponent,
    EntityPrimaryAlertComponent,
    EntityOutdatedModalComponent,
    EntityConfirmModalComponent,
    EntityHasDependenciesModalComponent,
    ReportFileEditorComponent,
    CustomEntityListingComponent,
    CustomReportingEntityListingComponent,
    CustomEntityFormContentComponent
  ],
  providers: [
    EntitiesService,
    ValidatorConversionService,
    RelationService
  ],
  exports: [
    EntitySearchFieldComponent,
    EntitySearchInRelationComponent,
    EntityFormComponent,
    EntityFormContentComponent,
    EntityFormContentTableComponent,
    MultiPositionEditorComponent,
    EntityListComponent,
    ToManyEditorComponent,
    BelongsToEditorComponent,
    PrettyEntityShowerComponent,
    EntityPrimaryAlertComponent,
    CustomEntityListingComponent,
    EntitySearchByFieldComponent,
    BelongsToFormComponent,
    CustomReportingEntityListingComponent,
    EntityContentByFieldComponent,
    EntitySearchByFieldComponent,
    BelongsToFormComponent,
    CustomEntityFormContentComponent
  ],
  entryComponents: [
    EntityOutdatedModalComponent,
    EntityConfirmModalComponent,
    EntityHasDependenciesModalComponent
  ]
})
export class EntitiesModule {}
