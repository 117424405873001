import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { ApiSettings, StaticSettings } from '../../settings.class';
import { CountService } from '../services/count.service';
import { StaticService } from '../services/static.service';
import { TranslationService } from 'app/utility/services/translation.service';
import {UntypedFormControl} from '@angular/forms';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
    selector: 'con-static-missing',
    templateUrl: './static-missing.component.html',
    styles: [`.missing-title-positioning{width:100px; float: left;}` , `.fixed-height-container { height: 60px;}`, `.missing-select-positioning{width:170px; float: left; margin-top: 0px;}`]
})

export class StaticMissingComponent implements OnInit, OnDestroy {

    private componentDestroyed$: Subject<boolean> = new Subject();

    public missing: any;
    public noDataMessage = 'No data to display.';
    public loading = true;
    dataUnderATab = {}
    tabName = 'all';
    missingFilter = new UntypedFormControl('all');
    filterExpanded = true;
    updatedFilterData: any;
    initialized = false;
    sectors: any;

    private isParamsInitializedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isParamsInitialized$: Observable<boolean> = this.isParamsInitializedSubject.asObservable();
    private cancelPreviousLoadRequests: Subject<void> = new Subject<void>();

    constructor(private http: HttpClient,
                private router: Router,
                private countService: CountService,
                private staticService: StaticService,
                private transService: TranslationService) {}

    ngOnInit(): void {
      this.staticService.getSectorEndpoint().subscribe(sectors => {
        this.sectors = sectors;
      })
    }

    initPage() {
      this.initialized = true;
      this.loadPage(1);
      this.transService.onTranslationSaved.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
          this.resetFetchedObject();
          this.loadPage(1);
      })
    }

  loadPage(pageNumber: number) {
    this.loading = true;
    this.countService.setIsLoading(true);
    const url = this.getEndpoint(this.tabName);
    this.dataUnderATab[this.getActiveTab()] = [];
    const customParams = {
      page: String(pageNumber),
      exchange_ids: this.updatedFilterData,
      list_filter: this.missingFilter.value
    }
    const onlyPageParam = {
      page: String(pageNumber),
      exchange_ids: this.updatedFilterData
    }
    this.callAPI(customParams, onlyPageParam, url);
  }

  callAPI(customParams: any, onlyPageParam: any, url: string): void {
    this.cancelPreviousLoadRequests.next();
    this.isParamsInitialized$.pipe(
      switchMap(isEnabled => {
        if (isEnabled) {
          this.loading = true;
          return this.http.get<any>(url, { params: (this.getActiveTab() === 'all' || this.getActiveTab() === 'company data') && (this.missingFilter.value !== '') ? customParams : onlyPageParam })
            .pipe(takeUntil(this.cancelPreviousLoadRequests)); // Cancel previous request if new one is triggered
        } else {
          return new Observable(); // Return empty observable to hold the call
        }
      })
    )
    .subscribe(res => {
      this.countService.setCount('missing', res.total);
      this.missing = res;
      this.dataUnderATab[this.getActiveTab()] = res;
      this.loading = false;
      this.countService.setIsLoading(false);
    }, err => {
      this.loading = false;
      this.countService.setIsLoading(false);
      if (err.isPermissionError()) {
        this.router.navigate(['static', 'auth'], { skipLocationChange: true });
      }
    });
  }

    removeFromList(company: any) {
        this.resetFetchedObject()
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        this.missing.data.splice(indx, 1);
        if (!this.missing.data.length) {
            this.initPage();
        }
    }
    resetFetchedObject() {
        if (this.tabName === 'all') {
            this.dataUnderATab = {}
        } else {
            this.dataUnderATab['all'] = null
        }
    }

    updateInList(company: any) {
        const indx = this.missing.data.findIndex(c => c.id === company.id);
        company.positions = this.missing.data[indx].positions;
        company.required_languages = this.missing.data[indx].required_languages;
        company.descriptions = this.missing.data[indx].descriptions;
        this.missing.data[indx] = company;
        this.resetFetchedObject()
    }

    whenFixed(company: any) {
        this.removeFromList(company);
        // set count based api response
        const url = this.getEndpoint(this.tabName);
        // check
        const params = {
          list_filter: this.missingFilter.value
        }
        if (this.getActiveTab() === 'all' || this.getActiveTab() === 'company data') {
          this.countService.setIsLoading(true);
          this.http.get<any>(url, { params }).pipe(take(1)).subscribe(res => {
            this.countService.setCount('missing', res.total);
            this.countService.setIsLoading(false);
          }, (error) => {
            console.error(error);
            this.countService.setIsLoading(false);
          })
        } else {
          this.countService.setIsLoading(true);
          this.http.get<any>(url).pipe(take(1)).subscribe(res => {
            this.countService.setCount('missing', res.total);
            this.countService.setIsLoading(false);
          }, (error) => {
            console.error(error);
            this.countService.setIsLoading(false);
          })
        }
    }

    onTabSelected(tabName) {
      this.tabName = tabName;
      this.loadPage(1);
    }

    getActiveTab(): string {
        return this.tabName ? this.tabName : 'all';
    }

    getEndpoint(tabName: any) {
        return this.staticService.getEndpoint(tabName, 'static')
    }

    ngOnDestroy() {
        this.componentDestroyed$.next();
        this.componentDestroyed$.complete();
    }

  onUpdateMissingFilter() {
    this.staticService.setMissingFilter(this.missingFilter.value);
    this.loadPage(1);
  }

  canShowMissingFilterDropdown() {
    return this.tabName === 'all' ||  this.tabName === 'company data';
  }

  updateFilterConfiguration(filterUpdatedData: any) {
    this.updatedFilterData = filterUpdatedData.exchange_ids;
    if(!this.initialized)
      this.isParamsInitializedSubject.next(true);
    this.initPage();
  }
}
