import { Component, Input, Output, EventEmitter, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ValidatorConversionService } from '../../services/validator-conversion.service';
import { EntityDescription, EntitiesService } from '../../services/entities.service';
import { EntityConfirmModalComponent } from '../../components/views/entity-confirm-modal.component';
import { ProfileService } from '../../../auth/services/profile.service';

import { snakeCase } from 'change-case';

import { SubscriptionLike as ISubscription } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { prettyCase } from 'app/shared/utilities.classes';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslationService } from 'app/utility/services/translation.service';
import { EntityAttachmentsService } from '../../../shared/services/entity-attachments.service';
import { EntityName } from '../../models/EntityName';
import { CalendarEventSettings } from 'app/settings.class';
import { take, timestamp } from 'rxjs/operators';

 @Component({
    selector: 'con-entity-form-content',
    templateUrl: './entity-form-content.component.html'
})

export class EntityFormContentComponent implements OnInit, OnChanges, OnDestroy {
    @Input() initialSaveEnabled = false;
    @Input() fromMissingEvents = false;
    @Input() entityName: string;
    @Input() entity: any;
    @Input() fixedValues: any = {};
    @Input() preFillValues: any = {}
    @Input() removeRelations = false;
    @Input() canStore: boolean;
    @Input() canEdit: boolean;
    @Input() canShow: boolean;
    @Input() canDelete: boolean;
    @Input() emptyAfterSave = false;
    @Input() except: any = [];
    @Input() only: any = [];
    @Input() hidden = false;
    @Input() showWhenChanged = true;
    @Input() toastMessage = '';
    @Input() fromCalendar = false;
    @Input() showEditButton = false;
    @Input() ownerType: string;
    @Input() setEntityFormContent: boolean = true;
    @Input() rows = [{
        id: null,
        showEdit: false
    }];
    @Input() newTab = false;
    @Input() fromShareholder = false;
    @Input() parentCompany: any;
    @Input() reloadEditData: boolean;
    @Input() resetKey: string;
    @Input() fromDetailPage = false;
    @Input() fromReports = false;
    @Input() combinedStatementsEdit = false;
    @Input() redirectButton = {
      show: false,
      label: '',
      link: ''
    };
    @Input() combinedStatements = false;
    @Input() fromPressRelease = false;
    @Input() addCommentSection = false;
    @Input() direktProfileLanguage: any;

    @Output() afterSave: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() showComments: EventEmitter<any> = new EventEmitter<any>();
    @Output() addNewRow: EventEmitter<any> = new EventEmitter<any>();
    @Output() afterOwnershipChange: EventEmitter<any> = new EventEmitter<any>();

    public isSaving: boolean;
    private hasErrors: boolean;
    public fieldErrors: any = {};
    public generalErrors: any = [];
    public entityDescription: EntityDescription;
    public entityForm: UntypedFormGroup;
    public entityDescriptions: any = {};
    private fixedValuesForChildForms: any = {};
    private oppositeRelations: any = {};
    public fields: any = [];
    public obj: any;
    public loaders: any = {};
    public loading: boolean;
    public resetForm = true;
    public _canStore: boolean;
    public _canEdit: boolean;
    public _canShow: boolean;
    public _canDelete: boolean;
    private profileReadySubscription: ISubscription;
    public forceDelSubscription: ISubscription;
    public forceDeleteSubscription: ISubscription;
    public EntityName = EntityName;
    public commentFormFields: any
    public commentEntity: any
    public autoTranslateMessage = CalendarEventSettings.CALENDAR_EVENT_AUTO_TRANSLATE_MESSAGE

    constructor(private service: EntitiesService,
                private formBuilder: UntypedFormBuilder,
                private validatorConverter: ValidatorConversionService,
                private profileService: ProfileService,
                private toastr: ToastrService,
                private router: Router,
                private modalService: NgbModal,
                private route: ActivatedRoute,
                private toaster: ToastrService,
                private translationService: TranslationService,
                private attachmentService: EntityAttachmentsService
                ) {}

    isLoading() {
        return this.service.isLoading();
    }

    generatePermissions() {
        this._canShow = (this.canShow === undefined) ? this.profileService.can
            (this.profileService.getPermissionName(this.entityName, 'show')) : this.canShow;
        this._canStore = (this.canStore === undefined) ? this.profileService.can
            (this.profileService.getPermissionName(this.entityName, 'store')) : this.canStore;
        this._canEdit = (this.canEdit === undefined) ? this.profileService.can
            (this.profileService.getPermissionName(this.entityName, 'update')) : this.canEdit;
        this._canDelete = (this.canDelete === undefined) ? this.profileService.can
            (this.profileService.getPermissionName(this.entityName, 'delete')) : this.canDelete;
    }

    ngOnInit() {
        this.profileReadySubscription = this.profileService.whenReady()
            .subscribe(res => {
                if (res) {
                    this.generatePermissions();
                }
            });
        this.router.events.subscribe(route => {
                                        this.setFormResetFlag(true);
                                    });

        this.forceDelSubscription = this.service.forceDelSub.subscribe(force => {
            const entity = this.setEntityFormContent ? this.service.getEntityForEntityFormContent() : {...this.entity}
          if (
              (force?.entity?.company?.id && entity.id && force?.entity?.company?.id === entity.id && force.name === this.entityName) ||
              (force?.entity?.source?.id && entity.id && force?.entity?.source?.id === entity.id && force.name === this.entityName) ||
              (force?.entity?.id && entity.id && force?.entity?.id === entity.id && force.name === this.entityName)
            ) {
                this.isSaving = true;
                this.forceDeleteSubscription = this.service.forceDelete(force.name, force.entity.id).subscribe(res => {
                    this.obj = null;
                    this.hasErrors = false;
                    this.isSaving = false
                    this.fieldErrors = {};
                    this.afterDelete.emit(res);
                    this.toastr.success('Deleted successfully!', 'Force delete');
                    if (this.emptyAfterSave) {
                        this.empty();
                    }
                    if (this.fromDetailPage) {
                        this.redirectToListing();
                    }
                }, (error) => {
                  this.isSaving = false;
                  this.toaster.error('Something went wrong. Please contact the administrator.', 'Force delete');
                });
            }
        }, err => {
          if(err.hasOwnProperty('type')){
            if(err.type === "LOCKED_ERROR" && !this.entity.locked){
              this.toaster.warning('ML data is being processed', 'ML Error');
            }
          }
          this.isSaving = false;
        });
        this.service.companyReportFileEditingSubject.subscribe(entity => {
            this.reloadEditData = true;
        });
        this.attachmentService.reset();
    }

    ngOnDestroy() {
        this.profileReadySubscription.unsubscribe();
        this.forceDelSubscription.unsubscribe();
        if (this.forceDeleteSubscription) {
            this.forceDeleteSubscription.unsubscribe();
        }
    }

    setFormResetFlag(value: boolean) {
        this.resetForm = value;
    }

    ngOnChanges() {
      this.loading = true;
      if (this.entity && (this.service.isEntityLocked(this.entity, this.entityName) || this.service.entityLockedByMl(this.entity))) {
        // check entity is locked
        this.canEdit = false;
        this._canShow = true;
      }
      if (this.entity && this.service.isEntityCalculated(this.entity, this.entityName)) {
        this.canEdit = false;
        this._canShow = true;
      }
      if (this.entity && !(Object.keys(this.entity).length === 0 && this.entity.constructor === Object)) {
        this.service.setEntityForEntityFormContent(this.entity);
      }
      if (!this.entity) {
        this.obj = {};
      } else {
        if(this.entityName === EntityName.PRESS_RELEASE && this.entity.publication_date) {
          this.entity.publication_date = {
            date_with_utc: typeof this.entity.publication_date === 'string'? this.entity.publication_date : this.entity.publication_date.date_with_utc,
            publication_date_timezone: this.entity.publication_date_timezone ? this.entity.publication_date_timezone : null
          }
        }
        this.obj = this.entity;
      }
      if (this.allowPrefilling()) {
        this.service.getPrefillData(this.entityName, this.fixedValues, this.parentCompany).subscribe(prefill => {
          this.obj = {
            ...this.obj,
            ...prefill
          }
          this.preFillValues = {
            ...this.preFillValues,
            ...prefill
          }
          this.prepareForm();
        })
      } else {
        this.prepareForm()
      }
      this.generatePermissions();
    }

    allowPrefilling() {
        if (this.fixedValues && this.fixedValues.company_report_id && !this.entityAlreadyExists() && this.parentCompany && this.parentCompany.id) {
            if (this.entityName === 'IncomeStatement' || this.entityName === 'CustomCombinedStatements' || this.entityName === 'BalanceSheet' || this.entityName === 'CashFlow') {
                return true
            }
        }
        return false
    }
    prepareForm() {
      if(this.combinedStatements) {
        const cs = {
          "fields": [
            {
              "type": "day",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": true,
              "disabled": false,
              "show_in_table": true,
              "show_in_store": true,
              "show_in_update": true,
              "key": "to_date",
              "label": "Period End Date",
              "rules": [
                "string",
                "date",
                "required",
                "date_format:Y-m-d"
              ],
              "format": "Y-m-d"
            },
            {
              "type": "day",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": true,
              "disabled": false,
              "show_in_table": true,
              "show_in_store": true,
              "show_in_update": true,
              "key": "from_date",
              "label": "Period Start Date",
              "rules": [
                "string",
                "date",
                "required",
                "date_format:Y-m-d"
              ],
              "format": "Y-m-d"
            },
            {
              "type": "string",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": false,
              "disabled": false,
              "show_in_table": false,
              "show_in_store": false,
              "show_in_update": false,
              "key": "unit.",
              "label": "Unit ",
              "rules": [
                "string",
                "nullable"
              ]
            },
            {
              "type": "int",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": false,
              "disabled": false,
              "show_in_table": false,
              "show_in_store": false,
              "show_in_update": false,
              "key": "unit_id",
              "label": "Unit Id",
              "rules": [
                "numeric",
                "required"
              ]
            },
            {
              "type": "string",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": false,
              "disabled": false,
              "show_in_table": false,
              "show_in_store": false,
              "show_in_update": false,
              "key": "quantity.",
              "label": "Quantity ",
              "rules": [
                "string",
                "nullable"
              ]
            },
            {
              "type": "int",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": false,
              "disabled": false,
              "show_in_table": false,
              "show_in_store": false,
              "show_in_update": false,
              "key": "quantity_id",
              "label": "Quantity Id",
              "rules": [
                "numeric",
                "required"
              ]
            },
            {
              "type": "string",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": false,
              "disabled": false,
              "show_in_table": false,
              "show_in_store": false,
              "show_in_update": false,
              "key": "incomeStatementFigures.",
              "label": "Incomestatementfigures ",
              "rules": [
                "string",
                "nullable"
              ]
            },
            {
              "type": "string",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": false,
              "disabled": false,
              "show_in_table": false,
              "show_in_store": false,
              "show_in_update": false,
              "key": "companyReport.",
              "label": "Companyreport ",
              "rules": [
                "string",
                "nullable"
              ]
            },
            {
              "type": "int",
              "is_searchable": false,
              "is_filterable": true,
              "is_primary": false,
              "disabled": false,
              "show_in_table": false,
              "show_in_store": false,
              "show_in_update": false,
              "key": "company_report_id",
              "label": "Company Report Id",
              "rules": [
                "numeric",
                "required"
              ]
            },
            {
              "type": "boolean",
              "is_searchable": false,
              "is_filterable": false,
              "is_primary": true,
              "disabled": false,
              "show_in_table": false,
              "show_in_store": true,
              "show_in_update": false,
              "key": "income_statement",
              "label": "Income Statement",
              "rules": [
                "boolean"
              ]
            },
            {
              "type": "boolean",
              "is_searchable": false,
              "is_filterable": false,
              "is_primary": true,
              "disabled": false,
              "show_in_table": false,
              "show_in_store": true,
              "show_in_update": false,
              "key": "balance_sheet",
              "label": "Balance Sheet",
              "rules": [
                "boolean"
              ]
            },
            {
              "type": "boolean",
              "is_searchable": false,
              "is_filterable": false,
              "is_primary": true,
              "disabled": false,
              "show_in_table": false,
              "show_in_store": true,
              "show_in_update": false,
              "key": "cash_flow",
              "label": "Cash Flow",
              "rules": [
                "boolean"
              ]
            },
          ],
          "relations": [
            {
              "owner": "IncomeStatement",
              "name": "companyReport",
              "foreign_key": "company_report_id",
              "label": "Company Report",
              "type": "belongsTo",
              "is_standard_relation": true,
              "is_standard_count": false,
              "disabled": false,
              "required": true,
              "model": "CompanyReport"
            },
            {
              "owner": "IncomeStatement",
              "name": "incomeStatementFigures",
              "foreign_key": "income_statement_id",
              "label": "Income Statement Figures",
              "type": "hasMany",
              "is_standard_relation": false,
              "is_standard_count": false,
              "disabled": false,
              "required": false,
              "model": "IncomeStatementFigure"
            },
            {
              "owner": "IncomeStatement",
              "name": "quantity",
              "foreign_key": "quantity_id",
              "label": "Quantity",
              "type": "belongsTo",
              "is_standard_relation": true,
              "is_standard_count": false,
              "disabled": false,
              "required": true,
              "model": "Quantity"
            },
            {
              "owner": "IncomeStatement",
              "name": "unit",
              "foreign_key": "unit_id",
              "label": "Unit",
              "type": "belongsTo",
              "is_standard_relation": true,
              "is_standard_count": false,
              "disabled": false,
              "required": true,
              "model": "Unit"
            }
          ],
          "icon": "database",
          "name": "CustomCombinedStatements"
        }

        const customMeta = new EntityDescription(cs);
        this.entityDescription = customMeta;
        if (this.resetForm || this.reloadEditData) {
          this.reloadEditData = false;
          this.createForm();
        }
        this.prepareRelations();
        this.loading = false;
        this.setFormResetFlag(false);
      } else {
        this.service.getEntityDescriptionByEntityName(this.entityName).pipe(take(1))
          .subscribe((entityDescription: EntityDescription) => {
            this.entityDescription = entityDescription;
            if (this.resetForm || this.reloadEditData) {
              this.reloadEditData = false;
              this.createForm();
            }
            this.prepareRelations();
            this.loading = false;
            this.setFormResetFlag(false);
          });
      }
    }
    prepareRelations() {
        this.oppositeRelations = {};
        this.entityDescription.getBelongsToRelationsForForm().forEach(relation => {
            this.loaders[relation.name] = {};
            this.oppositeRelations[relation.name] = { required: false };
            this.service.getInverseRelations(relation)
                .subscribe(relations => {
                    this.oppositeRelations[relation.name] = relation
                });
            if (relation.model && !this.entityDescriptions[relation.model]) {
                this.service.getEntityDescriptionByEntityName(relation.model)
                    .subscribe(entityDescription => {
                        this.entityDescriptions[relation.model] = entityDescription;
                    });
            }
        });
    }
    objectHasKeys(obj: any) {
        return obj && Object.keys(obj).length > 0;
    }
    createForm(): void {
      const formStructure: any = {};
      this.hasErrors = false;
      this.fieldErrors = {};
      this.generalErrors = [];
      this.fields = [];
      this.getFields().forEach(field => {
            this.fields.push(field);
            if (this.fixedValues && !this.fixedValues[field.key]) {
                const structure = [];
                // Default value
                if (this.entityAlreadyExists() && (this.entity[field.key] ||  this.entity[field.key] === 0)) {
                  structure.push({ value: this.entity[field.key], disabled: field.disabled });
                } else if (this.preFillValues && this.preFillValues[field.key]) {
                    structure.push({ value: this.preFillValues[field.key], disabled: this.entityAlreadyExists() ? field.disabled : false });
                } else if (field.prefill && !this.entityAlreadyExists()) {
                  structure.push({ value: this.service.getFieldDefaultValue(field), disabled: this.entityAlreadyExists() ? field.disabled : false });
                } else {
                    structure.push({ value: '', disabled: this.entityAlreadyExists() ? field.disabled : false });
                }
                const validators = [];
                field.rules.forEach(rule => {
                    const validator = this.validatorConverter.getValidatorByString(rule);
                    if (validator) {
                        validators.push(validator);
                    }
                })
                structure.push(validators);
                formStructure[field.key] = structure;
            }
        });
        if (this.entityName === 'CompanyReport' && (!this.entity || !this.entity.id)) {
            delete formStructure.locked;
        }

        let attachmentsObject = [];
        let hasAttachments = false;
        if(formStructure.hasOwnProperty('attachments')) {
          attachmentsObject = formStructure.attachments;
          hasAttachments = true;
          delete formStructure.attachments;
        }
        this.entityForm = this.formBuilder.group(formStructure);

        if(hasAttachments) {
          if(attachmentsObject.length && attachmentsObject[0]?.value.length ){
            this.entityForm.addControl('attachments', this.formBuilder.array([]));
            for(let i = 0; i< attachmentsObject[0].value.length;i++) {
              (this.entityForm.get('attachments') as FormArray).push(this.formBuilder.group({
                id: attachmentsObject[0].value[i]?.id,
                source:  attachmentsObject[0].value[i]?.source,
                file_name:  null,
                name: '',
                ref_file_id: [attachmentsObject[0]?.value[i]?.ref_file_id, [this.validatorConverter.fileIdValidator]]
              }));
            }
          } else {
            this.entityForm.addControl('attachments', this.formBuilder.array([]));
          }
        }

        Object.keys(this.entityForm.controls).forEach(control => {
            this.entityForm.controls[control].valueChanges
                .subscribe(val => {
                    if (this.fieldErrors[control] &&
                        this.fieldErrors[control].length) { this.fieldErrors[control] = []; }
                })
        });
    }

    revert() {
        this.attachmentService.reset();
        this.setFormResetFlag(true);
        this.ngOnChanges();
    }

    entityAlreadyExists() {
        return this.obj && this.obj.id !== undefined;
    }
    getBelongsToRelations() {
        return this.entityDescription.getBelongsToRelationsForForm()
            .filter(r => this.shouldShowRelation(r));
    }
    shouldShowRelation(relation: any) {
        return (this.only.length ? this.only.indexOf(relation.name) > -1 : true)
            && this.except.indexOf(relation.name) === -1;
    }
    getFields() {
        let fields;
        if (this.entityAlreadyExists()) {
            fields = this.entityDescription.getFieldsForUpdateForm();
        } else {
            fields = this.entityDescription.getFieldsForStoreForm();
        }
        return fields.filter(field => {
            return this.only.length ? this.only.indexOf(field.key) > -1 : true
        })
            .filter(field => {
                return this.except.indexOf(field.key) === -1;
            });
    }
    delete() {
        const modalRef = this.modalService.open(EntityConfirmModalComponent,
            {
                size: 'md'
            });

          const data = {
              title: prettyCase(this.entityName),
              message: 'Are you sure you want to delete ?',
              alert: false
          }

          modalRef.componentInstance.modalData = data;
          modalRef.result.then((result) => {
            this.deleteConfirmed();
          }, (reason) => {
          });
    }
    deleteConfirmed() {
            this.isSaving = true;
            this.service.deleteEntity(this.entityDescription.name, this.obj)
                            .subscribe(
                            res => {
                                this.obj = null;
                                this.isSaving = false;
                                this.hasErrors = false;
                                this.fieldErrors = {};
                                this.afterDelete.emit(res);
                                this.toastr.success('Deleted successfully!', prettyCase(this.entityName) );
                                if (this.emptyAfterSave) {
                                    this.empty();
                                }
                                if (this.fromDetailPage) {
                                    this.redirectToListing();
                                }
                            },
                            error => {
                                if(error.hasOwnProperty('type')){
                                  if(error.type === "LOCKED_ERROR" && !this.entity.locked){
                                    this.toaster.warning('ML data is being processed', 'ML Error');
                                    this.hasErrors = true;
                                    this.isSaving = false;
                                    return;
                                  }
                                }
                                if (error.data && error.data.message) {
                                  this.toastr.warning(error.data.message, 'Delete');
                                } else if(!error.hasOwnProperty('type')) {
                                  this.toaster.error('Something went wrong. Please contact the administrator.', `Delete ${prettyCase(this.entityName)}`);
                                }
                                this.hasErrors = true;
                                this.isSaving = false;
                            });
    }
    fieldIsRequired(key) {
        let ret = false;
        this.getFields().forEach(f => {
            if (f.key === key) {
                ret = f.rules.indexOf('required') > -1;
            }
        });
        return ret;
    }
    onSubmit(force: boolean = false, edit: boolean = false) {
        this.generalErrors = [];
        this.isSaving = true;
        const entity: any = {};
        if (this.entityAlreadyExists()) {
            entity['id'] = this.obj.id;
            if (!force) {
                entity['updated_at'] = this.obj.updated_at;
            }
        }

        for (const key in this.entityForm.controls) {
            if (this.entityForm.controls.hasOwnProperty(key)) {
                const control = this.entityForm.controls[key];
                if (control.dirty || key === 'owner_type') {
                    if (this.entity) {
                        // Check if value is dirty
                        if (control.value !== this.entity[key]) {
                            entity[key] = control.value;

                            // Check for a new empty value
                        } else if (control.value === '' && this.entity[key] !== '') {
                            entity[key] = control.value;
                        }
                    } else {
                        if (control.value !== '') {
                            entity[key] = control.value;
                        }
                    }
                }
                // Field is required, include it if we do not exists
                if (!this.entityAlreadyExists()
                    && this.fieldIsRequired(key)) {
                    entity[key] = control.value;
                } else if (this.preFillValues?.hasOwnProperty(key)) {
                    entity[key] = control.value;
                } else {
                  const fieldDescription = this.fields.find(field => field.key === key);
                  if(fieldDescription && fieldDescription.hasOwnProperty('prefill') && fieldDescription.prefill !== null && !entity[key]){
                    entity[key] = control.value;
                  }
                }
            }
        }
        Object.keys(this.fixedValues).forEach(field => {
            entity[field] = this.fixedValues[field];
        });
        if (this.entityDescription.name === 'Owner') {
            if (entity.capital !== null && entity.capital !== undefined && entity.capital !== '') {
                if (entity.capital.toString().length) {
                    entity.capital = (entity.capital / 100).toFixed(4);
                }
            }
            if (entity.votes !== null && entity.votes !== undefined && entity.votes !== '') {
                if (entity.votes.toString().length) {
                    entity.votes = (entity.votes / 100).toFixed(4);
                }
            }
        }
        if (this.entity
            && this.entity.id
            && this.entityForm.controls.hasOwnProperty('locked')
            && this.entityForm.controls.locked.value !== this.entity.locked) {
            const modalRef = this.modalService.open(EntityConfirmModalComponent,
                {
                    size: 'md'
                });
              const lock = entity.locked ? 'lock' : 'unlock';
              const data = {
                  title: 'Lock',
                  message: 'Are you sure you want to ' + lock + ' this report ?',
                  alert: false
              }
              modalRef.componentInstance.modalData = data;
              modalRef.result.then((result) => {
                this.saveEntity(entity, edit);
              }, (reason) => {
                this.entityForm.controls['locked'].setValue(!entity.locked);
                this.isSaving = false;
                this.entityForm.controls.locked.markAsPristine();
              });
        } else {
            this.saveEntity(entity, edit)
        }
    }
    saveEntity(entity, edit) {
        if(entity.id === -1){
            entity.id = undefined;
        }
        if(this.combinedStatements) {
          if(entity.hasOwnProperty('from_date')) {
            entity.period_start_date = entity.from_date;
            delete entity.from_date;
          }
          if(entity.hasOwnProperty('to_date')) {
            entity.period_end_date = entity.to_date;
            delete entity.to_date;
          }
        }
        if(this.combinedStatementsEdit) {
          entity.combined_edit_mode = true;
          entity.statement_type = this.preFillValues.statement_type;
          entity.statement_id = this.fixedValues?.statement_id;
          delete entity.id;
        }
        if(this.fromMissingEvents) {
          delete entity.report_date_exactness;
          delete entity.data_entry_status;
        }
        // check multi array keys like 1. attachments

        if(this.entityForm.value.hasOwnProperty('attachments') && !entity.hasOwnProperty('attachments')){
          entity.attachments = this.entityForm.value.attachments;
        }

        if(entity.hasOwnProperty('attachments') && entity.attachments.length) {
          const mappedAttachments = [];
          for (let i = 0; i< entity.attachments.length; i++) {
            if(entity.attachments[i].id === null) {
              mappedAttachments.push({id: entity.attachments[i].id, file: entity.attachments[i].file_name, ref_file_id: entity.attachments[i].ref_file_id});
            } else {
              // edit mode check for ref_file_id changes
              const currentAttachment = this.entity.attachments.find(item => item.id === entity.attachments[i].id)
              if (currentAttachment && currentAttachment.ref_file_id !== entity.attachments[i].ref_file_id) {
                mappedAttachments.push({id: entity.attachments[i].id, file: entity.attachments[i].file_name, ref_file_id: entity.attachments[i].ref_file_id});
              }
            }
          }
          entity.attachments = mappedAttachments;
        }
        if(entity.hasOwnProperty('attachments') && this.attachmentService.attachmentIdsForDeletion.length) {
         this.attachmentService.deleteMarkedAttachments();
        }

        if(entity.hasOwnProperty('publication_date')) {
          let publishingDateCopy = {...entity.publication_date};
          entity.publication_date = publishingDateCopy?.date_with_utc ? publishingDateCopy?.date_with_utc : null;
          entity.publication_date_timezone = publishingDateCopy?.publication_date_timezone ? publishingDateCopy.publication_date_timezone  : null;
        }

        this.service.saveEntity(this.combinedStatements ? 'CombinedStatements' : this.entityDescription.name, entity)
            .subscribe(
                res => {
                    const promise = Promise.resolve(res);

                    if (this.removeRelations) {
                        this.entityDescription.getAllRelations().forEach(relation => {
                            if (res[relation.name]) { delete res[relation.name]; }
                        });
                    }
                    promise.then(() => {
                        if (this.entityAlreadyExists()) {
                            this.toastr.success(
                                this.toastMessage ? this.toastMessage : 'Updated successfully!', prettyCase(this.entityName)
                            );
                        } else {
                            if (this.fromCalendar) {
                                this.service.calendarEditingSubject.next(this.entityName);
                            }
                            this.toastr.success(
                                this.toastMessage ? this.toastMessage : 'Saved successfully!', prettyCase(this.combinedStatements ? 'Statements' : this.entityName)
                            );
                        }
                        this.obj = res;
                        this.isSaving = false;
                        this.hasErrors = false;
                        this.isDescriptionEntity(this.entityName, this.obj);
                        this.afterSave.emit(this.obj);
                        this.showComments.emit(edit);
                        if (this.emptyAfterSave) {
                            this.empty();
                        }
                        if(this.obj.attachments) {
                          this.obj.attachments = this.obj.attachments.filter(item => !this.attachmentService.attachmentIdsForDeletion.includes(item.id));
                      } else {
                        this.obj.attachments = [];
                      }
                      this.attachmentService.reset();
                      if(this.obj.hasOwnProperty('attachments')){

                          if(this.obj.attachments.length ){
                            (this.entityForm.get('attachments') as FormArray).clear();
                            for(let i = 0; i< this.obj.attachments.length;i++) {
                              (this.entityForm.get('attachments') as FormArray).push(this.formBuilder.group({
                                id:this.obj.attachments[i].id,
                                source:  this.obj.attachments[i].source,
                                file_name:  '',
                                name: '',
                                ref_file_id: [this.obj.attachments[i].ref_file_id, [this.validatorConverter.fileIdValidator]]
                              }));
                            }
                          }
                        }
                    });
                    this.entityForm.markAsPristine();
                },
                err => {
                    if (err.isValueError()) {
                        if (this.fromReports && (this.entityName === 'CompanyEmployee' || this.entityName === 'Shareholder')) {
                          const generalErrData = err.getData()?.general;
                            if (generalErrData.length && generalErrData[0].includes('already exist')) {
                                const relationName = this.entityName === 'CompanyEmployee' ? 'CompanyEmployees' : 'Shareholders';
                                const date = this.entityName === 'Shareholder' ? entity.shareholder_date : entity.date;
                                this.service.checkOwnershipExists('Company', this.parentCompany.id, relationName, date).subscribe(data => {
                                    if (data.data && data.data.length > 0) {
                                        this.confirmOwnership(entity, data.data[0].id, this.parentCompany.id);
                                    } else {
                                        this.handleError(err)
                                    }
                                });
                            } else {
                                this.handleError(err);
                            }
                        } else {
                            this.handleError(err);
                        }
                    } else if (err.isLockedError()) {
                      if(!this.entity.locked){
                        this.service.getAllNestedRelationNames('Survey').pipe(take(1)).subscribe((relations) => {
                          if(relations.includes(this.entityName)){
                            this.toaster.warning('This entity is locked', 'Locked error');
                          } else {
                            this.toaster.warning('ML data is being processed', 'ML Error');
                          }
                      });
                        this.isSaving = false;
                        return;
                      }
                      this.isSaving = false;
                      this.toastr.warning(err.getData().message, prettyCase(this.entityName));
                    } else {
                      this.toaster.error('Something went wrong. Please contact the administrator.', `Save ${prettyCase(this.entityName)}`);
                      this.isSaving = false;
                    }
                }
            )
        }
     handleError(err: any) {
        // console.log(err);
        this.isSaving = false;
        this.fieldErrors = err.getData().fields;
        this.generalErrors = err.getData().general;
        if(this.combinedStatements) {
         if(this.fieldErrors['period_end_date'] && this.fieldErrors['period_end_date'] .length){
           const endDateMessages = this.fieldErrors['period_end_date'];
           for(let i=0; i <endDateMessages.length; i++){
             endDateMessages[i] = (endDateMessages[i] as string).replaceAll('_', ' ')
           }
           this.fieldErrors.to_date = endDateMessages;
           delete this.fieldErrors.period_end_date;
         }
         if(this.fieldErrors['period_start_date'] && this.fieldErrors['period_start_date'] .length){
           const startDateMessages = this.fieldErrors['period_start_date'];
           for(let i=0; i <startDateMessages.length; i++){
             startDateMessages[i] = (startDateMessages[i] as string).replaceAll('_', ' ')
           }
           this.fieldErrors.from_date = startDateMessages;
           delete this.fieldErrors.period_start_date;
         }
        }
        this.hasErrors = true;
     }
     isDescriptionEntity(entityName: string, obj: any) {
         if (entityName === 'Description') {
            this.translationService.onDescriptionSaved(obj)
         }
     }
    pushToRelation(relation: any, obj: any) {
        if (!this.entity[snakeCase(relation.name)]) {
            this.entity[snakeCase(relation.name)] = [];
        }
        this.entity[snakeCase(relation.name)].push(obj);
    }
    spliceFromRelation(relation: any, obj: any) {
        const indx = this.entity[snakeCase(relation.name)].findIndex(o => o.id === obj.id);
        this.entity[snakeCase(relation.name)].splice(indx, 1);
    }
    resetErrors(key: string, value: string) {
        if (value) { this.fieldErrors[key] = []; }
    }
    resetErrorsForRelation(relation: any, value: any) {
        this.resetErrors(relation.foreign_key, value.model);
        if (value.type) {
            this.resetErrors(relation.foreign_type, value.type);
        }
        this.entityForm.get(relation.foreign_key).markAsDirty();
    }
    empty() {
        this.obj = {};
        this.revert();
    }
    addRow() {
        this.rows.push({
            id: null,
            showEdit: false
        })
    }
    removeRow(index: number) {
        this.rows.splice(index, 1);
    }

    getFixedSearchParams(relation: any) {
        const obj = {};
        const oppositeRelation = this.getOppositeRelation(relation);
        if (relation.type === 'hasOne') {
            obj[relation.foreign_key] = 'f:null';
        }
        if (oppositeRelation) {
            if (relation.type === 'hasMany') {
                obj[oppositeRelation.name + ':'] = 'f:null'
            }
        }
        return obj;
    }
    getOppositeRelation(relation) {
        return this.oppositeRelations[relation.name];
    }
    redirectToListing() {
        const urlChunks = this.router.url.split('/');
        urlChunks.splice(urlChunks.length - 1, 1);
        const url = urlChunks.splice(1, urlChunks.length);
        this.router.navigate(url);
    }
    confirmOwnership(entity, id, companyId) {
        const modalRef = this.modalService.open(EntityConfirmModalComponent,
            {
                size: 'md'
            });

          const data = {
              title: prettyCase(this.entityName),
              message: `A ${prettyCase(this.entityName)} already exist for the selected date. Do you want to change its ownership to Company report?`,
              alert: false
          }

          modalRef.componentInstance.modalData = data;
          modalRef.result.then((result) => {
            this.changeOwnership(entity, id);
          }, (reason) => {
            this.isSaving = false;
          });
    }

    changeOwnership(entity, id) {
        const data = {
            id: id,
            source_type: entity.source_type,
            source_id: entity.source_id
        }
        this.service.saveEntity(this.entityDescription.name, data)
            .subscribe(
                res => {
                    const promise = Promise.resolve(res);

                    promise.then(() => {
                            this.toastr.success(
                                this.toastMessage ? this.toastMessage : 'Updated successfully!', prettyCase(this.entityName)
                            );
                        this.obj = res;
                        if (this.entityDescription.name === 'Shareholder') {
                            this.afterOwnershipChange.emit(this.obj);
                        } else {
                            this.afterSave.emit(this.obj);
                        }
                        if (this.emptyAfterSave) {
                            this.empty();
                        }
                        this.isSaving = false;
                        this.hasErrors = false;
                    });
                },
                err => {
                    if (err.isLockedError()) {
                        this.toastr.warning(err.getData().message, prettyCase(this.entityName));
                    }
                    this.isSaving = false;
                }
            )
        }
 }
