<con-file-uploader *ngIf="shouldUseFileInput()" [type]="field.type" [control]="control" [rules]="field.rules"></con-file-uploader>
<input *ngIf="shouldUseTextInput()" class="form-control" type="text" [formControl]="control" [placeholder]="field.label" />
<input *ngIf="shouldUseUrlInput()" class="form-control" type="url" [formControl]="control" [placeholder]="field.label" />
<input *ngIf="shouldUseEmailInput()" class="form-control" type="email" [formControl]="control" [placeholder]="field.label" />
<input *ngIf="shouldUseIntegerInput() && shouldBeMasked()" class="form-control" type="text"
    [formControl]="control"
    [placeholder]="field.label"
    [imask]="mask"
    [unmask]="true"/>
<input *ngIf="shouldUseIntegerInput() && !shouldBeMasked()" class="form-control" type="number"
    [formControl]="control"
    [placeholder]="field.label" />
<input *ngIf="shouldUsePasswordInput()"class="form-control" type="password" [formControl]="control" [placeholder]="field.label" />
<textarea *ngIf="shouldUseTextAreaInput()" class="form-control" type="text" [formControl]="control" [placeholder]="field.label"></textarea>
<select *ngIf="shouldUseSelectInput()" class="form-control custom-select" [formControl]="control">
    <option *ngFor="let value of field.values" [ngValue]="value.value">{{value.label}}</option>
</select>
<con-checkbox-input *ngIf="shouldUseCheckbox()" [control]="control" [field]="field"></con-checkbox-input>
<con-date-input *ngIf="shouldUseDatePicker()"
            [control]="control" [field]="field" [resetKey]="field?.key" [entityForm]="entityForm"></con-date-input>

<con-day-input *ngIf="shouldUseDayPicker()"
            [field]="field" [control]="control" [resetKey]="resetKey" [entityForm]="entityForm" [reverseDates]="reverseDates"></con-day-input>

<input *ngIf="shouldUseNumberInput()" class="form-control" type="text" [imask]="radixMask" [unmask]="true" [formControl]="control" [placeholder]="field.label" />
<input *ngIf="shouldUseTelephoneInput()" class="form-control" type="tel" [formControl]="control" [placeholder]="field.label" />

<div class="editor" *ngIf="shouldUseEditorInput()">
  <angular-editor [formControl]="control" [config]="editorConfig"></angular-editor>
</div>
<div class="center" *ngIf="shouldUseMultiFileUpload()">
  <con-multi-file-uploader [entityForm]= 'entityForm' [control]='control' [isSaving]="isSaving"></con-multi-file-uploader>
</div>
