<div class="fixed-height-20" *ngIf="false"> <!-- AutoCalc toggle button is visually hidden for now and will be enabled later-->
  <label class="switch pull-right">
    <input type="checkbox" [checked]="autoCalc" (click)="toggleCalcOff()">
    <span class="slider round"></span>
  </label>
  <span class="switch-text pull-right mr-15" >Auto Calc</span>
</div>
<ul class="prev-val-ui">
  <li>
    <div ngbTooltip="" class="pull-right editor-year" *ngIf="cashFlow?.showPrevious">
      <select [formControl]="dateRangePicker" class="form-control custom-select editor-select" (ngModelChange)="_check()">
        <option *ngFor="let item of cashFlow?.past_statements" [value]="item.id">
          {{companyService.getComparisonPrefix(item?.company_report?.report_type)}} {{item?.from_date | date:'yyyy-MM-dd'}} - {{item?.to_date | date:'yyyy-MM-dd'}}
        </option>
      </select>
      <div class="pull-right w-60" *ngIf="loadingData">
        <con-loader [showCircularLoder]="true"></con-loader>
      </div>
    </div>
    <div ngbTooltip="" class="pull-right editor-year" *ngIf="cashFlow?.showPrevious">
      <span class="previous-value-header">{{cashFlow?.from_date | date:'yyyy-MM-dd'}} - {{cashFlow?.to_date |
        date:'yyyy-MM-dd'}}</span>
    </div>
  </li>
</ul>
<con-cash-flow-editor [treeData]="cashFlow.modules" [parent]="null" [showPrevious]="cashFlow.showPrevious" [entity]="entity"
    [quantity]="cashFlow.quantity?.multiplier" [locked]="locked" (onValueChanged)="enableActionButtons($event)"
    (onMaxValueReached)="onReachingMaxValue($event)" [autoCalc]="autoCalc">
</con-cash-flow-editor>
<con-no-data [count]="cashFlow?.modules?.length" [message]="noDataMessage"></con-no-data>
<div *ngIf="!processingTree">
    <button type="submit" [disabled]="!activateActionButtons || validationErrors.length>0" class="btn btn-success"
        (click)="saveTree(cashFlow.modules, cashFlow.id)">Save</button>
    <button type="reset" [disabled]="!activateActionButtons"
        (click)="revertTree(cashFlow.modules,cashFlow.quantity?.multiplier);  activateActionButtons = false; _check()"
        class="btn btn-warning">Revert</button>
    <button type="reset" class="btn btn-warning" (click)="clearFormData()">Clear</button>
</div>
<con-loader *ngIf="processingTree"></con-loader>
